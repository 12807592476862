import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n';

import moment from 'moment';
import 'moment/locale/ar';
import { logout, getToken, refreshToken, parseJwt } from './lib/auth';

import './plugins/vee-validate';
import './plugins/fetching';
import './plugins/youtube-embed';
import './plugins/loading';
import { openInNewTab } from './lib/share';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
// import { BrowserTracing } from "@sentry/tracing";
// import * as SentryVue from "@sentry/vue";
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import FlashMessage from '@smartweb/vue-flash-message';
import VueSkeletonLoader from 'skeleton-loader-vue';
import eImg from '@/components/eImg';
import VueLoadScript from 'vue-load-script-plus';
import pjson from '../package.json';
// import common css
import './assets/main.css';
import './assets/rtl.css';
import JsonViewer from 'vue-json-viewer';
import { mapMutations } from 'vuex';

import SocialSharing from 'vue-social-sharing';
SocialSharing.methods.touch = function (network) {
  if (network === 'whatsapp') {
    openInNewTab(this.createSharingUrl(network));
  } else {
    window.open(this.createSharingUrl(network), '_self');
  }
  this.$root.$emit('social_shares_open', network, this.url);
  this.$emit('open', network, this.url);
};

Vue.use(JsonViewer);
Vue.use(SocialSharing);
Vue.use(VueGeolocation);
Vue.use(FlashMessage);
Vue.use(VueLoadScript);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.component('e-img', eImg); //wrapper for v-img with error handling

Vue.config.productionTip = false;

const googleAPIKey = localStorage.getItem('VUE_APP_GOOGLE_API_KEY');

const ws_notify = Vue.observable({ ws_notify: {} });
Object.defineProperty(Vue.prototype, '$ws_notify', {
  get() {
    return ws_notify.ws_notify;
  },
  set(value) {
    ws_notify.ws_notify = value;
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: googleAPIKey, // process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    libraries: 'places',
  },
});
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {},
      });
    },
    data: () => ({
      extras_config: {},
      merchant_details: {
        admin_url: '',
        logo_url: '',
        favicon_url: '',
        title: '',
        title_en: '',
        title_ar: '',
        subheader: '',
      },
      networks: {
        whatsapp: {
          sharer: 'https://web.whatsapp.com/send?text=@description%0D%0A@url',
          type: 'popup',
        },
        test: {
          sharer: 'https://google.com',
          type: 'popup',
        },
      },
    }),
    async created() {
      Vue.prototype.isKeycloakAuth = this.isKeycloakAuth;
      Vue.prototype.$enableCHD = this.enableCHD;
      if (this.isKeycloakAuth) {
        Vue.prototype.$token = this.idToken;
        Vue.prototype.$logout = this.logout;
        Vue.prototype.$refreshToken = this.refreshToken;
        this.setUserDetails(
          this.sharedMFState.user,
          this.sharedMFState.sections,
        );
      } else {
        Vue.prototype.$token = getToken;
        Vue.prototype.$logout = logout;
        Vue.prototype.$refreshToken = refreshToken;
        Vue.prototype.$changeState = this.changeState;
        const token = getToken();
        if (token) {
          const parsedToken = parseJwt(token);
          if (parsedToken.exp * 1000 > Date.now())
            Vue.prototype.$changeState('state');
        }
      }
      Vue.prototype.$noNavBar = this.noNavBar;
      Vue.prototype.$customStyleDirectoryName = this.customStyleDirectoryName;
      Vue.prototype.$foodOrderingColor = this.foodOrderingColor;
      Vue.prototype.$orderRefreshTime = this.orderRefreshTime;
      Vue.prototype.$checkoutSDKLink = this.checkoutSDKLink;
      Vue.prototype.$showFromforPaymentPage = this.showFromforPaymentPage;
      Vue.prototype.$showUnitCodes = this.showUnitCodes;

      const lang = this.$route.path.slice(1, 3);
      if (lang === 'en' || lang === 'ar') {
        this.$i18n.locale = lang;
      }
      this.setLang();
      this.$vuetify.rtl = this.$i18n.locale === 'ar';
      moment.locale(this.$i18n?.locale || 'en');

      if (this.customStyleDirectoryName) {
        await import(
          './assets/servers/' + this.customStyleDirectoryName + '/index.css'
        );
      }

      Sentry.setTag('microfrontend', 'core_frontend');
      Sentry.init({
        dsn: this.sentryUrl,
        integrations: [
          new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
          // new BrowserTracing({
          //   tracePropagationTargets: [localStorage.getItem("MERCHANT_ID"), /^\//],
          //   routingInstrumentation: SentryVue.vueRouterInstrumentation(router)
          // })
        ],
        release: `core_frontend@${pjson.version}`,
        // tracesSampleRate:this.traceSampleRate,
        ignoreErrors: this.ignoredSentryErrors,
        beforeSend: this.checkSentryErrors,
        sampleRate: this.sentrySampleRate,
        autoSessionTracking: false,
        enabled: !this.isLocal,
      });
    },
    watch: {
      '$i18n.locale': function (lang, old_lang) {
        this.$vuetify.rtl = this.$i18n.locale === 'ar';
        moment.locale(this.$i18n?.locale || 'en');
      },
      ws_notification: {
        immediate: true,
        handler() {
          Vue.set(Vue.prototype, '$ws_notify', this.ws_notification);
        },
      },
      'sharedMFState.isLoadingConfig': function (val) {
        if (val === false) {
          this.setUserDetails(
            this.sharedMFState.user,
            this.sharedMFState.sections,
          );
        }
      },
    },
    methods: {
      setLang() {
        const translations = this.translations();
        this.$i18n.setLocaleMessage('en', translations.en || {});
        this.$i18n.setLocaleMessage('ar', this.arabicTranslation || {});
      },
      setUserDetails(user, sections) {
        this.setPermissions({
          isSuperUser: user.is_superuser,
          permsArray: user.user_permissions,
        });
        this.setEmail(user.email);
        this.setId(user.id);
        this.setSections(sections);
      },
      ...mapMutations('dashboard', [
        'setPermissions',
        'setEmail',
        'setId',
        'setSections',
      ]),
    },
    i18n,
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
